<template>
	<div class="mileage">
		<!-- header -->
		<homePage :urlFlag="3"></homePage>
        <!-- 丝路里程 -->
        <div class="container bannerBox newsList">
            <h2 class="bigTietle"> 
				<span>
					<img src="@/assets/smallLogo.png" alt="" class="smallLogo">
					丝路里程
				</span>
			</h2>
            <p class="news">SILK ROAD CALENDAR</p>
            <!-- 城市选择 -->
            <div class="addressBox">
                <div class="addressMode">
                    联系我们：Fisher YU
                    <span class="icon font_family icon-icon_phone_white"></span>18602865214
                    <span class="icon font_family icon-icon-mail_white"></span>srl-market@srleader.com
                </div>
                <div class="addressChoose">
                    <div class="departure" @click.stop="departureChoose = !departureChoose">
                        成都出发
                        <div class="icon font_family icon-icon_enter" :class="{rotateIcon:departureChoose}"></div>
                        <ul class="allDeparture" :style="{'max-height':departureChoose?'200px':'0'}">
                            <li @click.stop="chooseThisCity('cd')">成都出发</li>
                            <li @click.stop="chooseThisCity('xian')">西安出发</li>
                        </ul>
                    </div>
                    <div class="nextPrev">
                        <span class="icon font_family icon-icon_back"></span> Last Week
                    </div>
                    <div class="nextPrev nextPrev2">
                        Next Week <span class="icon font_family icon-icon_enter"></span>
                    </div>
                </div>
            </div>
            <div class="cityLeave">
                <div class="cityLeaveBox cityLeaveBoxTop">
                    <div class="leaveLine">线路</div>
                    <div class="timeBox">
                        <div>星期一</div>
                        <p>2021-01-21</p>
                    </div>
                    <div class="timeBox">
                        <div>星期二</div>
                        <p>2021-01-21</p>
                    </div>
                    <div class="timeBox">
                        <div>星期三</div>
                        <p>2021-01-21</p>
                    </div>
                    <div class="timeBox">
                        <div>星期四</div>
                        <p>2021-01-21</p>
                    </div>
                    <div class="timeBox">
                        <div>星期五</div>
                        <p>2021-01-21</p>
                    </div>
                    <div class="timeBox">
                        <div>星期六</div>
                        <p>2021-01-21</p>
                    </div>
                    <div class="timeBox">
                        <div>星期天</div>
                        <p>2021-01-21</p>
                    </div>
                </div>
                <div class="cityLeaveBox" v-for="(val,index) of 5" :key="index">
                    <div class="leaveLine">{{leaveLineData}}</div>
                    <div class="timeBox"></div>
                    <div class="timeBox timeBox1">充裕</div>
                    <div class="timeBox timeBox2">已满</div>
                    <div class="timeBox"></div>
                    <div class="timeBox"></div>
                    <div class="timeBox"></div>
                    <div class="timeBox"></div>
                </div>
            </div>
            <div class="contactUs contactUs2">
                <div class="contactTitle">联系我们</div>
                <div class="contactName">Fisher YU</div>
                <p><span></span>18602865214</p>
                <p><span></span>srl-market@srleader.com</p>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "mileage",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
            leaveLineData: '成都-布达佩斯',
            departureChoose: false
		}
	},
	methods: {
        chooseThisCity(paramCity) {
            switch (paramCity) {
                case 'xian':
                    this.leaveLineData = '西安-布达佩斯'
                    break;
                default:
                    break;
            }
            this.departureChoose = false
        },
        // 点击别的地方隐藏弹框
		closeAll() {
			this.departureChoose = false
		}
	},
	mounted() {
        this.$nextTick(() => {
			document.querySelector('body').addEventListener('click', this.closeAll);
		})
    },
    onBeforeUnmount() {
		document.querySelector('body').removeEventListener('click', this.handleBodyClick);
    }
};
</script>

<style scoped>
@import "mileage.css";
</style>
